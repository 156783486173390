export default [
  {
    key: "no",
    label: "ลำดับ",
    sortable: true,
    thStyle:{color:"white", width: "6% !important"}
  },
  {
    key: "date",
    label: "วันที่",
    sortable: true,
    thStyle:{color:"white"}
  },
  { key: "costName", label: "ค่าใช้จ่าย", sortable: true , thStyle:{color:"white", width: "12% !important"}},  

  { key: "billNo", label: "เลขที่บิล", sortable: true , thStyle:{color:"white"}},
  { key: "amount", label: "จำนวนเงิน", sortable: true ,thStyle:{color:"white",width: "12% !important",}},
  {
    key: "description",
    label: "รายละเอียด",
    sortable: true,
    thStyle:{color:"white"}
  },
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { textAlign: "center", color:"white" ,width: "8% !important",}
  },
];
