<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>แบบฟอร์มเคลียร์บิลใบเสร็จ</b></h3>
        <small>ระบบเบิกค่าใช้จ่าย > แบบฟอร์มเคลียร์บิลใบเสร็จ</small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="accountAdminPermission=='edit'"
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="onSaveIncentiveOt"
          
        >
          <feather-icon icon="SaveIcon" />
          บันทึกเอกสาร
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.go(-1)"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <IncentiveOt :incentiveOtData="incentiveOtData" />

        <b-card>
          <b-row>
            <b-col md="3">
              <label>วันที่ <span class="text-danger">*</span></label>
              <b-form-datepicker
                id="example-datepicker3"
                locale="th-TH"
                v-model="formClearbillItem.date"
                show-decade-nav
                class=""
                size="sm"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="2">
              <label>ค่าใช้จ่าย</label>
              <v-select
                id="problemType"
                v-model="formClearbillItem.cost"
                label="title"
                :options="costOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
            <b-col md="3">
              <label>เลขที่บิล <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="เลขที่บิล"
                size="sm"
                value="เลขที่บิล"
                v-model="formClearbillItem.billNo"
              />
            </b-col>
            <b-col md="2">
              <label>จำนวนเงิน <span class="text-danger">*</span></label>
              <b-form-input
                placeholder="0.00"
                size="sm"
                value="0.00"
                v-model.number="formClearbillItem.amount"
              />
            </b-col>
            <b-col>
              <label>รายละเอียด</label>
              <b-form-input
                placeholder="รายละเอียด"
                size="sm"
                value="รายละเอียด"
                v-model="formClearbillItem.description"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="incentiveOtData.processNo==1"> 
            <b-col v-if="accountAdminPermission=='edit'">
              <b-button
                variant="primary"
                @click="onSaveClearBill"
                :disabled="isLoading "
              >
                <span v-if="isLoading">กำลังบันทึก...</span>
                <span v-else>
                  <span v-if="mode == 'create'">เพิ่มรายการ</span>
                  <span v-else>บันทึกการแก้ไข</span>
                </span>
              </b-button>
              <b-button
                class="ml-1"
                variant="danger"
                @click="clearFormClearBill"
              >
                ล้างข้อมูล
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive            
             
              :items="clearBillList"
              :fields="fields"             
           
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(action)="row" v-if="accountAdminPermission=='edit'">
                <center>
                  <b-button
                    class="mr-1"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="onEditIncentiveClearBill(row.item)"
                   :disabled="incentiveOtData.processNo!==1"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer text-secondary"
                    />
                  </b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    pill
                    @click="deleteIncentiveClearBill(row.item.id)"
                    :disabled="incentiveOtData.processNo!==1"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer text-white"
                    />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="2">       
            <b>ยอดรวมทั้งหมด : {{ footerSummaryAmount }} บาท</b>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//COMPONENT
import vSelect from "vue-select";
import IncentiveOt from "./component/incentiveOt.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BSpinner
} from "bootstrap-vue";

//SERVICE
import IncentiveOTService from "@/services/incentiveOT";
import { GET_CURRENT_DATE } from "@/utils/";

//MODEL
import IncentiveOtModel from "./model/incentiveClearbill";
import ClearBillField from "./field/incentiveClearBill";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BTable,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
    IncentiveOt,
    BSpinner
  },
  data() {
    return {
      incentiveOtData: IncentiveOtModel,
      costOption: [
        { title: "ค่าแก็ส", value: 1 },
        { title: "ค่าโรงแรม", value: 2 },
        { title: "ค่าทางด่วน", value: 3 },
        { title: "ค่าน้ำมัน", value: 4 },
        { title: "ค่าที่จอดรถ", value: 5 },
        { title: "ค่าแท็กซี่", value: 6 },
        { title: "ค่าซ่อมรถ", value: 7 },
        { title: "ค่าอื่นๆ", value: 8 },
      ],
      userLogin: {},
      isLoading: false,
      formClearbillItem: {
        date: "",
        cost: { title: "ค่าแก็ส", value: 1 },
        billNo: "",
        description: "",
        amount: 0,
      },
      clearBillList: [],
      fields: ClearBillField,
      mode: "create",
      footerSummaryAmount: 0,
    };
  },
  async created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;

    //WHEN EDIT MODE
    const paramIncentiveId = this.$route.params.incentiveId;
    if (paramIncentiveId) {
      this.onLoadIncentiveOtById(paramIncentiveId);
      this.onLoadIncentiveClearBill();
    }
  },

  computed :{
    accountAdminPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("AC002")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
  },

  methods: {
    validateFormIncOt(){
      if(!this.incentiveOtData.otherDescription){
        alert("กรุณากรอกรายละเอียดใบเบิก")
        return true
      }
      if(!this.incentiveOtData.otherRealGoDate){
        alert("กรุณาเลือกวันที่เดินทางไป")
        return true
      }
      if(!this.incentiveOtData.otherRealBackDate){
        alert("กรุณาเลือกวันที่เดินทางกลับ")
        return true
      }
      if(!this.incentiveOtData.otherTotalDate){
        alert("กรุณาเลือกจำนวนวันที่เดินทาง")
        return true
      }
    },
    validateFormBillDetail(){
      if(!this.formClearbillItem.date){
        alert("กรุณาเลือกวันที่")
        return true
      }
      if(!this.formClearbillItem.billNo){
        alert("กรุณากรอกเลขที่บิล")
        return true
      }
      if(!this.formClearbillItem.amount){
        alert("กรุณากรอกจำนวนเงิน")
        return true
      }
    
    },
    async onLoadIncentiveOtById(incentiveOtId) {
      try {
        const result = await IncentiveOTService.GetIncentiveOTById(
          incentiveOtId
        );
        this.incentiveOtData = result.data;
        this.incentiveOtData.otherTotalDate = {
          title: this.incentiveOtData.otherTotalDate,
          value: this.incentiveOtData.otherTotalDate,
        };
        this.incentiveOtData.otherRentalDay = {
          title: this.incentiveOtData.otherRentalDay,
          value: this.incentiveOtData.otherRentalDay,
        };
        this.incentiveOtData.otherExpensesPerDay = {
          title: this.incentiveOtData.otherExpensesPerDay,
          value: this.incentiveOtData.otherExpensesPerDay,
        };
      } catch (error) {
        alert("Load Incentive Ot Data Error");
        console.log(error);
      }
    },

    async onLoadIncentiveClearBill() {
      this.isLoading = true;
      const paramIncentiveId = this.$route.params.incentiveId;
      const result = await IncentiveOTService.GetIncentiveClearBillList(
        paramIncentiveId
      );
      this.clearBillList = result.data;
      this.computeSummaryAmountFooter(result.data)
      this.isLoading = false;
    },

    async updateIncentiveOt(isSendApprove = false) {
      try {
        let obj = {
          ...this.incentiveOtData,
          otherRentalDay: this.incentiveOtData.otherRentalDay.value,
          otherTotalDate: this.incentiveOtData.otherTotalDate.value,      
        };

        if(isSendApprove){
          obj ={
            dateSend: GET_CURRENT_DATE().timestamp,
            processNo:2
          }
        }

        
        const result = await IncentiveOTService.UpdateIncentiveOT(
          obj,
          this.incentiveOtData.id
        );

        if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "CheckIcon",
              variant: "success",
              text: `บันทึกข้อมูลใบเบิกค่าเดินทางเรียบร้อย`,
            },
          });
          this.$router.push("/accounting");
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการบันทึกข้อมูลใบเบิกค่าเดินทาง`,
            },
          });
        }
      } catch (error) {
        alert("Create Incentive Ot Error");
        console.log(error);
      }
    },

    async onSaveIncentiveOt() {
      //เมื่อกดบันทึกเอกสาร
      const invalid = this.validateFormIncOt()
      if(invalid) return;

      await this.updateIncentiveOt();
      this.$router.push(`/accounting/admin/approve/${this.incentiveOtData.id}`);
    },

    onEditIncentiveClearBill(item) {
      this.mode = "edit";
      this.formClearbillItem = {
        ...item,
        cost: { title: item.costName, value: item.feetype },
      };
    },



    async onSaveClearBill() {
      const invalid = this.validateFormBillDetail()
      if(invalid) return;

      if (this.mode == "create") {
        this.insertIncentiveClearBill();
      } else {
        this.editIncentiveClearBill();
      }
    },

    async insertIncentiveClearBill() {
      const paramIncentiveId = this.$route.params.incentiveId;
      let obj = {
        ...this.formClearbillItem,
        orderNo: 1,
        fkId: paramIncentiveId,
        feetype: this.formClearbillItem.cost.value,
      };

      this.isLoading = true;
      const result = await IncentiveOTService.InsertIncentiveClearBill(obj);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการ Bill เรียบร้อย`,
          },
        });
        this.clearFormClearBill();
        this.onLoadIncentiveClearBill();
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ Bill `,
          },
        });
      }
      this.isLoading = false;
    },

    async editIncentiveClearBill() {
      this.isLoading = true;
      const paramIncentiveId = this.$route.params.incentiveId;
      let obj = {
        ...this.formClearbillItem,
        orderNo: 1,
        fkId: paramIncentiveId,
        feetype: this.formClearbillItem.cost.value,
      };

      const result = await IncentiveOTService.UpdateIncentiveClearBill(
        obj,
        obj.id
      );
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });
        this.clearFormClearBill();
        this.onLoadIncentiveClearBill();
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
      this.isLoading = false;
    },

    async deleteIncentiveClearBill(incentiveClearBillId) {
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่ ?");
      if (!isConfirm) {
        return false;
      }

      this.isLoading = true;
      const result = await IncentiveOTService.DeleteIncentiveClearBill(
        incentiveClearBillId
      );
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "CheckIcon",
            variant: "success",
            text: `ลบรายการเรียบร้อย`,
          },
        });
        this.onLoadIncentiveClearBill();
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการลบรายการ`,
          },
        });
      }
      this.isLoading = false;
    },



    clearFormClearBill() {
      this.mode = "create";
      this.formClearbillItem = {
        date: "",
        cost: { title: "ค่าแก็ส", value: 1 },
        billNo: "",
        description: "",
        amount: 0,
      };
    },
    computeSummaryAmountFooter(lists) {
      const sumMoney = lists.reduce(
        (acc, value) => acc + value.amount,
        0
      );
      this.footerSummaryAmount = sumMoney;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
